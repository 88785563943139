<template>
    <div class="container-fluid">

      <div class="text-center title-block">
        <h1>Условия туристического слета</h1>
      </div>
  <div class="col-md-10 mx-auto">
    <div class="card mt-2 mx-auto">
      <div class="card-body">
      <p>
        Заполняя форму для регистрации оргвзноса Вы соглашаетесь на обработку ваших персональных данных.
      </p>
      <p>Внимание! В случае форс-мажорных обстоятельств, повлекших за собой отмену или перенос события -
        оргвзнос не возвращается, а ваши средства будут зачтены при следующих мероприятиях, организованных
        командой Space of Joy Community.<br><br>  <b>Правила перерегистрации оргвзноса</b> <br>Если Вы решили
        передать ваш оргвзнос другому человеку, то Вам необходимо перерегистрировать его на другое имя.<br>
        Для этого напишите письмо на <a href="ticket@spaceofjoy.ru" target="_blank">ticket@spaceofjoy.ru</a>
        или в телеграм <a href="https://t.me/systo_vznos" target="_blank">@systo_vznos</a> <br>

      </p>
    </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: "AboutView",
  created() {
    document.title = "Условиями туристического слёта"
  }
}
</script>